import React, { useState, useEffect } from "react";
import style from "./CreateTaskComponent.module.css";
import calendar from "../../../../assets/calendar.png";
import cancel from "../../../../assets/cancell.png";
import "react-calendar/dist/Calendar.css";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import Select from "react-select";
import constants, { employeeId } from "../../../../Utils/constants";
import RequiredFieldIndication from "./RequiredFieldIndication";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import { customStylesForSelectTaskTracker, customStylesForTaskTracker, customStylesForTaskTracker2, customStylesForTaskTrackerSpaces } from "../../../Projects_and_Resource_Master/Clients/component/style";
import { taskTrackerAccessCode } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getActiveMembersBySpace } from "../../taskTrackerAPIs/taskTrackerAPI";

const CreateTaskComponent = () => {
  const location = useLocation();
  const groupIdFromUrl = location.state?.groupId;
  const { checkValidateAccess } = useValidationAccess();
  const currentDate = Moment().toDate();
  const [startDate1, setStartDate] = useState();

  const [functionalArea, setFunctionalArea] = useState([]);
  const [peojectList, setProjectList] = useState([]);
  const [disable, setDisable] = useState(true);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick} style={{height: "30px"}}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = ({groupId, setGroupid}) => {
    if (!groupId) return null; 
    return (
      <span style={{position: "absolute", height: "38px", width: "100%", zIndex: '1', display: "flex", alignItems: "center", paddingBottom: "2px" }}>
        <img
          src={cancel}
          alt="dropdownIcon"
          style={{ position: "absolute", zIndex: "1", marginLeft: "83%", cursor: "pointer"}}
          height="15"
          onClick={() => {setGroupid(null)}}
        />
      </span>
    );
  };

  const [employeeName, setEmployeeName] = useState("");
  const [relatedTo, setRelataedTo] = useState("");
  const [relatedToId, setRelataedToId] = useState(null);
  const [showRes, setshowRes] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [groupList, setGroupsList] = useState([]);
  const [arrayObj, setarrayObj] = useState([]);
  const [employeeIds, setEmployeeIds] = useState(0);
  const [option, setOption] = useState("");
  const [projectoptin, setProjectOption] = useState(null);
  const [funtionalAreaOpt, setFunctionalOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [groupId, setGroupid] = useState(location.state?.groupId);
  const [state, setState] = useState({
    description: "",
    title: "",
  });
  const history = useHistory();
  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const FuncTionalAreaClear = () => {
    setFunctionalOption(null);
    setFunctionalArea([]);
    getFunctionalArea();
  };
  const getemployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getGroups = groupList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  functionalArea?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  function handleChange(id) {
    setEmployeeIds(id);
  }

  function handleChange1(id) {
    setRelataedToId(id);
  }

  function handleOption(e) {
    e.preventDefault();
    setOption(e.target.value);
  }

  function handleOption1(e) {
    e.preventDefault();
    setProjectOption(e.target.value);
  }

  function handleOption2(e) {
    e.preventDefault();

    if (e.target.value == 1) {
      setDisable(false);
      getProject();
    } else {
      // setDisable(true);
      setProjectList([]);
      setProjectOption(null);
    }
    setFunctionalOption(e.target.value);
  }
  const hideAlert = (data) => {
    setTimeout(() => {
      setshowRes(false);
      handleRedirect(data);
    }, 1000);
  };

  const handleRedirect = () => {
    groupIdFromUrl
      ? history.push("/Groups")
      : history.push("/taskTracker/TaskAssignedByMeComponent");
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;

  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setFunctionalArea(res.data.responseData);
      })
      .catch(() => { });
  };

  const getProject = () => {
    axios
      .get(baseUrl + "/getAllActiveProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch(() => { });
  };

  function postData(e) {
    if (
      !employeeIds ||
      !startDate1 ||
      !option ||
      !state?.title

    ) {
      setValidate(true);
      return;
    }
    e.preventDefault();
    const data = {
      assignedTo: employeeIds,
      planedEndDate: Moment(startDate1).format("YYYY-MM-DD hh:mm"),
      priority: option,
      title: state.title.trim(),
      functionalFieldId: funtionalAreaOpt,
      project: projectoptin,
      taskRelatedToId: relatedToId,
      groupId: groupId,
    };

    if (state.description) {
      data.description = state.description.trim();
    }
    setIsLoading(true);
    axios
      .post(url2 + "/createTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 1) {
          // setShowErr(true);
          return;
        }
        if (res.data.responseStatus) {
          // setShowErr(true);
        } else {
          setshowRes(true);
          setShowErr(false);
          hideAlert(res.data.responseData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
  }

  const {data:getActiveMember} = useQuery(
    ["getActiveMembersBySpace", groupId],
    ()=>getActiveMembersBySpace(groupId),
    {
      refetchOnWindowFocus: false,
      enabled: !!groupId
    }
  );
  const getActiveMembers = getActiveMember?.map((i)=>({
    value: i.memberId,
    label: i.memberName,
  }));

  useEffect(() => {
    async function employeedata() {
      await axios
        .get(url + "/getEmployeeList", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          setEmployeeList(res.data.responseData.employeeList);
          setarrayObj(
            employeeList.map((item) => {
              return {
                label: item.name,
                id: item.id,
              };
            })
          );
        })
        .catch((err) => {
          return err;
        });
    }
    employeedata();
  }, []);

  useEffect(() => {
    async function getGroupData() {
      await axios
        .get(url2 + `/groupListing?empId=${employeeIds}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          if(Array.isArray(res.data.responseData)) setGroupsList(res.data.responseData);
        })
        .catch((err) => {
          return err;
        });
    }
    getGroupData();
  }, [employeeIds]);
  function onChange(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  useEffect(() => {
    getFunctionalArea();
    getProject();
  }, []);
  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span>Create Task</span>
        {showRes && (
          <div className={style.alert} id="alert_msg">
            Task added successfully!
          </div>
        )}
        <div className={style.createTask}>
          <div id="create_task_id" className={style.gridContainer}>
            <div>
              <div style={{ width: '95.5%' }}>
                <label className={style.font15}>
                  Task Title<span style={{ color: "red" }}>*</span> <br></br>
                  <input
                    className={style.input}
                    autocomplete="off"
                    type="input"
                    id="tast_title"
                    name="title"
                    value={state.title}
                    style={{ marginTop: "10px" }}
                    onChange={onChange}
                  />
                  <p
                    className={
                      validate && !state?.title
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Task Title
                  </p>
                </label>
              </div>
              <div>
                <label className={style.descLabel}>
                  Task Description
                  <textarea
                    className={style.text}
                    style={{ color: "black" }}
                    name="description"
                    value={state.description}
                    onChange={onChange}
                    id="task_description"
                  />
                </label>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Assigned To
                    <span style={{ color: "red" }}>*</span>
                    <Select
                      styles={customStylesForSelectTaskTracker}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setEmployeeName(e.label);
                        handleChange(e.value);
                      }}
                      options={getemployee}
                    />
                    <p
                      className={
                        validate && !employeeIds
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Assigned To
                    </p>
                  </label>
                </div>
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Priority<span style={{ color: "red" }}>*</span> <br />
                    <select onChange={(e) => handleOption(e)} style={{marginTop: "5px"}}>
                      <option value="" disabled="" hidden selected="">
                        ---select---
                      </option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                    <p
                      className={
                        validate && !option
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Priority
                    </p>
                  </label>
                </div>
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Planned End Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className={style.input}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      minDate={new Date()}
                      selected={startDate1}
                      startDate={null}
                      
                      onChange={(date) => setStartDate(date)}
                      // disabledKeyboardNavigation
                      // defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !startDate1
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Planned End Date
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Space
                    <div style={{ marginTop: "10px", position: "relative" }}>
                        {groupId && <CustomInputCross groupId={groupId} setGroupid={setGroupid} />}
                      <Select
                        styles={customStylesForTaskTrackerSpaces}
                        placeholder="Search By Client Name"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={groupId ? getGroups?.find(
                          (i) => i.value === groupId
                        ) : null }
                        isDisabled={!!groupIdFromUrl}
                        onChange={(e) => {
                          setDisable(false)
                          setGroupid(e.value);
                        }}
                        options={getGroups}
                      />
                    </div>
                  </label>
                </div>
                {/* <div style={{ width: '33%' }}>
                  <label className={style.font15} style={{ marginTop: "-4px" }}>
                    Project
                    <br />
                    <select
                      onChange={(e) => handleOption1(e)}
                      disabled={disable}
                    >
                      <option value="" disabled="" hidden selected="">
                        ---select---
                      </option>
                      {peojectList?.map((val) => {
                        return <option value={val.name}>{val?.name}</option>;
                      })}
                    </select>
                  </label>
                </div> */}
                {/* <div style={{ width: '33%' }}>
                  <label className={style.font15}>
                    Task Related To{showErr && <RequiredFieldIndication />}{" "}
                    <br />
                    <div style={{ marginTop: "10px" }}>
                      <Select
                        styles={customStylesForTaskTracker2}
                        classNamePrefix={"create_position"}
                        placeholder="Search By Client Name"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          setRelataedTo(e.label);
                          handleChange1(e.value);
                        }}
                        options={getemployee}
                      />
                    </div>
                  </label>
                </div> */}
              </div>
            </div>
            {/* <div>
              <div>
                <div
                  className={style.priorityLabel}
                  style={{ marginTop: "14.7px" }}
                >


                  <label className={style.font15}>
                    Functional Area {showErr && <RequiredFieldIndication />}{" "}
                    <br />
                    <select onChange={(e) => handleOption2(e)}>
                      <option value="" disabled="" hidden selected="">
                        ---select---
                      </option>
                      {functionalArea?.map((val) => {
                        return (
                          <option value={val.id} data-icon>
                            {val?.name}
                          </option>
                        );
                      })}
                    </select>
                    {funtionalAreaOpt ? (
                      <img
                        src={cancel}
                        style={{
                          cursor: "pointer",
                          height: "12px",
                          position: "absolute",
                          left: "0",
                          marginLeft: "15rem",
                          marginTop: "23px",
                        }}
                        onClick={FuncTionalAreaClear}
                      />
                    ) : null}
                  </label>
                </div>
              </div>

              <div>

              </div>
            </div> */}
          </div>
        </div>
        {checkValidateAccess(taskTrackerAccessCode?.submit_CreateTask) && (<div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
          <button className={style.cancelButton} onClick={()=>history.goBack()}>Cancel</button>
          
            <button
              className={style.submitButton}
              onClick={postData}
              disabled={isLoading}
            >
              Submit
            </button>
          
        </div>)}
      </div>
    </div>
  );
};

export default CreateTaskComponent;
