import React, { useState, useEffect, useRef } from "react";
import style from "./TaskAssignedByMeDetails.module.css";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import pencilEditButtonImg from "../../../../assets/pencil-edit-button.png";
import rubbishBinImg from "../../../../assets/rubbish-bin.png";
import { MdDelete } from "react-icons/md";
import { TiPencil } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import Pagination from "../../commonComponent/Pagination";
import rubbishBinImg2 from "../../../../assets/rubbish-bin (2).png";
import pencil from "../../../../assets/pencil-edit-button (2).png";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import Modal from "react-responsive-modal";
import constants, {
  employeeId,
  taskTrackerAccessCode,
} from "../../../../Utils/constants";
import ConfirmPopup from "./ConfirmPopup";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import loaderImg from './../../../../assets/loader-green2.gif';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const arr = [
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, co consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit ametamet, consectetur adipisci",
  },
];

const TaskAssignedByMeDetails = (props) => {
  const { checkValidateAccess } = useValidationAccess();
  const location = useLocation();
  const commentText = useRef(null);
  const taskId = props?.history.location.state?.task?.taskId;
  const [data, setData] = useState("");
  const [commentHistory, setcommentHistory] = useState([]);
  const [editComment, seteditComment] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [newData, setnewData] = useState(props?.history.location.state.task);
  const [isdeleted, setIsdeleted] = useState(false);
  const [commentToEdit, setcommentToEdit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showErr, setShowErr] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmCommentModal, setConfirmCommentModal] = useState(false);
  const [taskCommentId, setTaskCommentId] = useState();
  const [myTaskDetail, setMyTaskDetailByID] = useState();
  const [returnPage, setreturnPage] = useState(
    props?.history.location.state.activePage
  );
  const profile = cookie.load("profile");
  const [openAddCloseNote, setOpenCloseNote] = useState(false);
  const [closeNote, setCloseNote] = useState("");
  const [doneReviewNote, setDoneReviewNote] = useState("");
  const [openDoneRevNote, setOpenDoneRevNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const [adCmt, setAdCmt] = useState(false);
  const [closingTask, setClosingTask] = useState(false);

  const limit = 3;

  const handleCommentEdit1 = (comment) => {
    setcommentToEdit(comment);
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const EditComment = () => {
    history.push("./taskTracker/Models/EditComment");
  };

  const url2 = constants.apiBaseUrl2;

  const deleteTaskComment = () => {
    axios
      .delete(url2 + `/deleteTaskComment?taskCommentId=${taskCommentId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then(() => {
        setTaskCommentId(undefined);
        handleConfirmCommentPopupClose();
        pageCount > 0 && currentPage > 1 && commentHistory.length === 1
          ? setCurrentPage(currentPage - 1)
          : setCurrentPage(currentPage);
        handleapidata();
      })
      .catch((err) => {
        return err;
      });
  };

  const getSingleDetailsById = (id) => {
    setIsLoading(true);
    axios
      .get(url2 + `/getTaskDetailsByTaskId?empId=${employeeId}&taskId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMyTaskDetailByID(res.data.responseData);
        setIsLoading(false);
      })
      .catch((err) => { });
  };
  const handleTaskEdit = (comment) => {
    history.push({
      pathname: "/EditComments",
      state: {
        comment: comment,
        newData: myTaskDetail,
        activePage: 2,
      },
    });
  };

  const directEdit2 = (data2) => {
    // if (returnPage == 1) {
    //   history.push("/viewTask", data2);
    // } else {
    history.push({
      pathname: "/EditTask",
      state: {
        task: data2,
        activePage: 3,
      },
    });
    // }
  };

  const deleteData = () => {
    setreturnPage(1);
    axios
      .delete(url2 + `/deleteTask?taskId=${taskId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        notifySuccess("Deleted Successfully !");
        handleConfirmPopupClose()
        history.push("/taskTracker/TaskAssignedByMeComponent");
      })
      .catch((err) => {
        return err;
      });

    // }, 10000);
    // setundoo(d1);
  };

  const postData = (e) => {
    setData(e.target.value);
  };

  var dateTime1 = Moment(Moment().toDate()).format("YYYY-MM-DD hh:mm");

  const Data = {
    localDateTime: dateTime1,
    closingDate: Moment(Moment().toDate()).format(),
    taskId: taskId,
    comment: data?.trim(),
  };

  const AddComment = () => {
    if (Number(Data.comment.length) === 0) {
      setShowErr(true);
      return false;
    }
    setAddingComment(true);
    axios
      .post(url2 + "/addTaskComment", Data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
        } else {
          setShowErr(false);
          commentText.current.value = "";
          setData("");
        }
        handleapidata();
        setAddingComment(false);
      })
      .catch((err) => {
        return err;
      });
  };

  const addNoteComment = (comment, status) => {
    const tempData = { ...Data };
    tempData.comment = comment
    setAdCmt(true);
    axios
      .post(url2 + "/addTaskComment", tempData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          notifyError("Something went wrong")
        } else {
          if (status === "close") {
            setCloseNote("")
            ClosedTask("Closed")
          }
          else if (status === "done(review)") {
            setDoneReviewNote("")
            MarkDone("Done")
          }
        }
        setAdCmt(false);
      })
      .catch((err) => {
        return err;
      });
  }

  const redirectHistory = () => {
    history.push({
      pathname: "/taskHistory",
      state: {
        task: myTaskDetail,
        activePage: returnPage,
      },
    });
  };
  const ClosedTask = (closed) => {
    const data = {
      taskId: myTaskDetail?.taskId,
      title: myTaskDetail?.title,
      description: myTaskDetail?.description,
      planedEndDate: Moment(myTaskDetail?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: myTaskDetail?.assignedTo,
      priority: myTaskDetail?.priority,
      status: closed,
      employeeRelatedToTask: myTaskDetail?.taskRelatedToEmployeeId,
      functionalFieldId: myTaskDetail?.functionalFieldAreaId,
      projectName: myTaskDetail?.projectName,
      localDateTime: Moment(myTaskDetail?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      closingDate: Moment(Moment().toDate()).format(),
    };
    setClosingTask(true);
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
          // history.push({
          //   pathname: "/taskTracker/ClosedByMeComponent",
          // });
        } else {
          // history.push({
          //   pathname: "/taskTracker/ClosedByMeComponent",
          // });
        }
        getSingleDetailsById(props?.history.location.state.task?.taskId);
        handleapidata()
        setOpenCloseNote(false);
        setClosingTask(false);
      })
      .catch((err) => {
        notifyError("Something went wrong!");
        return err;
      });
  };

  function DesCriptChanges(description) {
    if (!description) return "";

    // Updated regex pattern to match "http", "https" and "www" URLs
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;

    // Split the description by newline characters
    const newlineSplit = description.split('\n').map((line, index) => {
      // Split the line by URLs and replace URLs with anchor tags
      const parts = line.split(urlPattern).map((part, subIndex) => {
        if (urlPattern.test(part)) {
          const url = part.startsWith('www') ? `http://${part}` : part;
          return (
            <a href={url} key={`${index}-${subIndex}`} target="_blank" rel="noopener noreferrer" className={style.blueLink}>
              {part}
            </a>
          );
        }
        return part;
      });

      return (
        <React.Fragment key={index}>
          {parts}
          {index < description.split('\n').length - 1 && <br />}
        </React.Fragment>
      );
    });

    return newlineSplit;
  }


  const MarkDone = () => {
    const data = {
      taskId: myTaskDetail?.taskId,
      title: myTaskDetail?.title,
      description: myTaskDetail?.description,
      planedEndDate: Moment(myTaskDetail?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: myTaskDetail?.assignedTo,
      priority: myTaskDetail?.priority,
      status: "Done",
      employeeRelatedToTask: myTaskDetail?.taskRelatedToEmployeeId,
      functionalFieldId: myTaskDetail?.functionalFieldAreaId,
      projectName: myTaskDetail?.projectName,
      localDateTime: Moment(myTaskDetail?.plannedEndDate).format("YYYY-MM-DD hh:mm"),
    };
    setClosingTask(true);
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
          // history.push({
          //   pathname: "/taskTracker",
          // });
        } else {
          // history.push({
          //   pathname: "/taskTracker",
          // });
        }
        getSingleDetailsById(props?.history.location.state.task?.taskId);
        handleapidata()
        setOpenDoneRevNote(false);
        setDoneReviewNote("");
        setClosingTask(false);
      })
      .catch((err) => {
        return err;
      });
  };

  const handleapidata = () => {
    const commentList = axios
      .get(
        url2 +
        "/getTaskComment?limit=" +
        limit +
        "&page=" +
        currentPage +
        "&taskId=" +
        taskId,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        const responseData = res.data.responseData;
        setcommentHistory(responseData.comments);
        setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
      })
      .catch((err) => {
        return err;
      });
  };

  const history = useHistory();
  useEffect(() => {
    handleapidata();
    getSingleDetailsById(props?.history.location.state.task?.taskId);
  }, [currentPage]);

  function handleConfirmPopupClose() {
    setConfirmModal(false);
  }

  function handleConfirmCommentPopupClose() {
    setConfirmCommentModal(false);
  }
  function handleConfirmCommentPopupOpen(commentId) {
    setTaskCommentId(commentId);
    setConfirmCommentModal(true);
  }
  return (
    <div>
      <AddClosingNote
        openAddCloseNote={openAddCloseNote}
        setOpenCloseNote={setOpenCloseNote}
        note={closeNote}
        setNote={setCloseNote}
        addCloseNote={() => {if(!adCmt) addNoteComment(closeNote, "close")}}
        closeTask={() => {if(!closingTask) ClosedTask("Closed")}}
      />
      <AddDoneRevNote
        doneReviewNote={doneReviewNote}
        setDoneReviewNote={setDoneReviewNote}
        openDoneRevNote={openDoneRevNote}
        setOpenDoneRevNote={setOpenDoneRevNote}
        addDoneRevNote={() => {if(!adCmt) addNoteComment(doneReviewNote, "done(review)")}}
        doneTask={() => {if(!closingTask) MarkDone("Done")}}
      />
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
          <div className={style.taskAssigned}>
            {isLoading ? <div>
                  <img src={loaderImg} alt="loader" className={style.loader} />
                  <p className={style.loaderPara}>Loading...</p>
                </div> : <div style={{ padding: "16px" }}>
              <div className={style.container}>
                <div className={style.subcontainer}>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "100%" }}
                  >
                    <h5>Task Title</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.title}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "100%" }}
                  >
                    <h5>Task Description</h5>
                    <p>
                      {isdeleted ? "" : DesCriptChanges(myTaskDetail?.description)}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Creation Date</h5>
                    {/* <p>{isdeleted ? "" : newData?.created}</p> */}
                    <p>{myTaskDetail?.creationDate}</p>
                  </div>

                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Planned End Date</h5>
                    <p>
                      {isdeleted
                        ? ""
                        : Moment(myTaskDetail?.plannedEndDate).format("DD MMM (ddd)")}
                    </p>
                  </div>
                  {(location?.pathname === "/taskAssignedByMeDetails") && <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>
                      Assigned To
                    </h5>
                    <p>
                      {isdeleted
                        ? ""
                        : myTaskDetail?.assignedToName}
                    </p>
                  </div>}
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Priority</h5>
                    <p>{isdeleted ? "" : myTaskDetail?.priority}</p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Status</h5>
                    <p
                      className={
                        myTaskDetail?.status === "Assigned"
                          ? style.asigned_color
                          : myTaskDetail?.status === "Done"
                            ? style.done_color
                            : myTaskDetail?.status === "Onhold"
                              ? style.onhold_color
                              : style.Closed_color
                      }
                    >
                      {myTaskDetail?.status === "Closed"
                        ? "Closed"
                        : myTaskDetail?.status === "Onhold"
                          ? "On Hold"
                          : myTaskDetail?.status}
                      {/* {newData.status==="Inprogress"?"In Progress":newData.status} */}
                    </p>
                  </div>
                  {/* <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                    >
                    <h5>Functional Area</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.functionalFieldArea}
                    </p>
                  </div> */}
                  {/* <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Project</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.projectName}
                    </p>
                  </div> */}
                  {/* <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Task Related To</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.taskRelatedToEmployee}
                    </p>
                  </div> */}

                  {location?.pathname==="/MyTaskDetailsDetails" &&
                    <div
                      className={style.subcontainerinner}
                      style={{ width: "25%" }}
                    >
                      <h5>Assigned By</h5>
                      <p className={style.titlee}>{myTaskDetail?.assignedByName}</p>
                    </div>
                  }
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Space</h5>
                    <p className={style.titlee}>
                      {isdeleted ? "" : myTaskDetail?.groupName}
                    </p>
                  </div>
                </div>

                <div className={style.rr} style={{ textAlign: "right" }}>
                  {
                    checkValidateAccess(
                      taskTrackerAccessCode?.done_Review_MyTask
                    ) && myTaskDetail?.doneTaskButtonVisibility && (
                      <button
                        disabled={myTaskDetail?.status === "Done" || myTaskDetail?.status === "Closed"}
                        onClick={() => {
                          setOpenDoneRevNote(true);
                          setDoneReviewNote("");
                        }}
                        className={(myTaskDetail?.status === "Done" || myTaskDetail?.status === "Closed") ? style.ttDoneDisabledBtn : style.TaskDone}
                      >
                        Done
                      </button>
                    )}

                  {
                    checkValidateAccess(taskTrackerAccessCode?.close_Task_TaskAssigned) && myTaskDetail?.closeButtonVisibility && (
                      <button
                        disabled={myTaskDetail?.status === "Closed"}
                        onClick={() => setOpenCloseNote(true)}
                        className={myTaskDetail?.status === "Closed" ? style.ttCloseddisabledbtn : style.closed}
                      >
                        Close Task
                      </button>
                    )
                  }


                  {checkValidateAccess(
                    taskTrackerAccessCode?.view_Task_History_TaskAssigned
                  ) && (
                      <button
                        onClick={() => redirectHistory()}
                        className={style.taskHistory}
                      >
                        View Task History
                      </button>
                    )}

                  {returnPage !== 1 &&
                    checkValidateAccess(
                      taskTrackerAccessCode?.delete_TaskAssigned
                    ) && myTaskDetail?.deleteTaskButtonVisibility && (
                      <button onClick={()=>setConfirmModal(true)} className={style.delete}>
                        Delete
                      </button>
                    )}

                  {isdeleted && (
                    <button
                      className={style.edit}
                      onClick={() => {
                        setIsdeleted(false);
                        setreturnPage(props.history.location.state.activePage);
                      }}
                    >
                      Undo
                    </button>
                  )}
                  {props?.history.location.pathname === "/MyTaskDetailsDetails"
                    ? null
                    : checkValidateAccess(
                      taskTrackerAccessCode?.edit_TaskAssigned
                    ) && myTaskDetail?.editTaskButtonVisibility && (
                      <button
                        onClick={() => directEdit2(myTaskDetail)}
                        className={style.edit}
                      >
                        Edit
                      </button>
                    )}
                </div>
                <br />
              </div>
              {myTaskDetail?.commentButtonVisibility && <div
                style={{ marginTop: "10px", marginBottom: "10px" }}
                id="add_commentid"
              >
                <h5 className={style.comment}>Add Comment</h5> <br />
                <div className={style["text-area-container"]}>
                  <textarea
                    onChange={postData}
                    name="textArea"
                    rows={3}
                    ref={commentText}
                  // className={style.scrollAdd}
                  />
                </div>
                {showErr && (
                  <div className={style.req_msg}>
                    <p>* Task comment can not empty.</p>
                  </div>
                )}
                {checkValidateAccess(
                  taskTrackerAccessCode?.add_Comment_TaskAssigned
                ) && (
                    <button onClick={AddComment} className={style.addComment} disabled={addingComment}>
                      Add Comment
                    </button>
                  )}
              </div>}

              {myTaskDetail?.commentButtonVisibility && <div className={style.tabl}>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                  className="comment2"
                >
                  Comment History
                </span>
                {commentHistory?.length > 0 ? (
                  <div>
                    {/* <TableScrollbar rows={2}> */}
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: '6rem' }}>
                            Date
                          </th>
                          <th
                            style={{ width: '10rem' }}
                          >
                            Name
                          </th>
                          <th
                            style={{ width: '34rem' }}
                          >
                            Comment
                          </th>
                          <th
                          style={{ width: '4rem' }}

                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      {/* <form className={style.formtable}> */}
                      <tbody>
                        {commentHistory &&
                          commentHistory.map((comment1, index1) => {
                            return (
                              <tr key={index1}>
                                {editComment && (
                                  <EditComment
                                    taskComment={commentToEdit}
                                    newData={myTaskDetail}
                                  />
                                )}
                                <td >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: "#65A15E",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {Moment(comment1.taskModifiedDate).format(
                                      "DD MMM"
                                    )}
                                  </span>
                                </td>
                                <td
                                >
                                  <span
                                    style={{
                                      color: "#65A15E",
                                      fontStyle: "italic",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {comment1.name}
                                  </span>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {comment1.taskComment}
                                  </p>
                                </td>

                                <td
                                  style={{
                                  }}
                                >
                                  {comment1.name === profile.name ? (
                                    <div style={{minWidth: "52px"}}>
                                      <TiPencil
                                        style={{ cursor: "pointer" }}
                                        size={20}
                                        onClick={() => handleTaskEdit(comment1)}
                                      />
                                      <MdDelete
                                        size={20}
                                        onClick={() =>
                                          handleConfirmCommentPopupOpen(
                                            comment1.taskCommentId
                                          )
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div style={{minWidth: "52px"}}>
                                      <img
                                        alt="img"
                                        style={{ cursor: "pointer" }}
                                        // onClick={() => handleTaskEdit(comment1)}
                                        src={pencil}
                                      />
                                      <img
                                        alt="img"
                                        // onClick={ () => deleteTaskComment(comment1.taskCommentId) }
                                        style={{
                                          paddingLeft: "11px",
                                          cursor: "pointer",
                                        }}
                                        src={rubbishBinImg2}
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      {/* </form> */}
                    </table>
                    {/* </TableScrollbar> */}

                    {commentHistory.length > 0 && pageCount > 0 && (
                      <div
                        className={style.paginationmobileContainer}
                        style={{ textAlign: "right" }}
                      >
                        <div className={style.Pagin}>
                          <Pagination
                            pagesCount={pageCount}
                            pageSize={limit}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={style.table}>No Data Available</div>
                )}
              </div>}
              {myTaskDetail?.commentButtonVisibility && <div className={style.mobileContainer}>
                {arr.map((ar, indexar) => {
                  return (
                    <div
                      key={indexar}
                      style={{
                        display: "block",
                        position: "relative",
                        borderBottom: "1px solid #707070",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Reported Date :
                        </span>
                        {ar.a}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Username :
                        </span>
                        {ar.b}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Comment :
                        </span>
                        {ar.c}
                      </p>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <img
                          alt="img"
                          style={{ cursor: "pointer" }}
                          onClick={handleCommentEdit1}
                          src={pencilEditButtonImg}
                        />
                        <img
                          alt="img"
                          style={{ paddingLeft: "10px" }}
                          src={rubbishBinImg}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>}
            </div>}
          </div>
        </div>
      </div>
      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Do you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteData}>
              Yes
            </button>
            <button className={style.noBtn} onClick={handleConfirmPopupClose}>
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={confirmCommentModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteTaskComment}>
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={handleConfirmCommentPopupClose}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

const AddClosingNote = ({
  openAddCloseNote,
  setOpenCloseNote,
  note,
  setNote,
  addCloseNote,
  closeTask
}) => {
  const [validate, setValidate] = useState(false);
  const handleAddNote = () => {
    if (!note) {
      closeTask()
    } else {
      addCloseNote();
    }
  };
  return (
    <Modal
      open={openAddCloseNote}
      center
      onClose={() => setOpenCloseNote(false)}
      showCloseIcon={false}
      className={style.modal}
    >
      <div className={style.modalHead}>
        Comment
      </div>
      <div className={style.modalInput}>
        <textarea
          className={style.inpBox}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        {validate && !note && (
          <div style={{ color: "red", fontSize: "15px" }}>
            Please Enter Closing Note
          </div>
        )}
      </div>
      <div className={style.btnROW}>
        <button
          className={style.ttcancelBtn}
          onClick={() => {
            setNote("");
            setOpenCloseNote(false);
            setValidate(false);
          }}
        >
          Cancel
        </button>
        <button className={style.ttAddBtn} onClick={() => handleAddNote()}>
          Close Task
        </button>
      </div>
    </Modal>
  );
};

const AddDoneRevNote = ({
  openDoneRevNote,
  setOpenDoneRevNote,
  doneReviewNote,
  setDoneReviewNote,
  addDoneRevNote,
  doneTask
}) => {
  const [validate, setValidate] = useState(false);
  const handleAddDoneRevNote = () => {
    if (!doneReviewNote) {
      doneTask()
    } else {
      addDoneRevNote();
    }
  };
  return (
    <Modal
      open={openDoneRevNote}
      center
      onClose={() => setOpenDoneRevNote(false)}
      showCloseIcon={false}
      className={style.modal}
    >
      <div className={style.modalHead}>
        Comment
      </div>
      <div className={style.modalInput}>
        <textarea
          className={style.inpBox}
          value={doneReviewNote}
          onChange={(e) => setDoneReviewNote(e.target.value)}
        />
        {validate && !doneReviewNote && (
          <div style={{ color: "red", fontSize: "15px" }}>
            Please Enter Done(Review) Note
          </div>
        )}
      </div>
      <div className={style.btnROW}>
        <button
          className={style.ttcancelBtn}
          onClick={() => {
            setDoneReviewNote("");
            setOpenDoneRevNote(false);
            setValidate(false);
          }}
        >
          Cancel
        </button>
        <button
          className={style.ttAddBtn}
          onClick={() => handleAddDoneRevNote()}
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default TaskAssignedByMeDetails;
