import axios from "axios";
import cookie from "react-cookies";
import { employeeId } from "../constants";
const apiKey = process.env.REACT_APP_Expence_URL;
const apiKey1 = process.env.REACT_APP_ATS_APP_URL;
const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
const baseUrl2 = process.env.REACT_APP_PORTAL_MASTER_URL;
const baseUrl3 = process.env.REACT_APP_EMPLOYEEONBOARDING_APP_URL;

let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const invoiceListData = async () => {
  const response = await axios.get(`${apiKey}/invoiceList?limit=10&page=1`);
  return response.data.responseData;
};

export const getCandidateRoles = async () => {
  const response = await axios.get(
    `${apiKey1}/getAllCandidateRoles
      `
  );
  return response.data.responseData;
};

export const getSkills = async () => {
  const response = await axios.get(
    `${apiKey1}/getSkills
    `
  );
  return response.data.responseData;
};

export const addSkill = async ({ skill }) => {
  let data = { name: skill };
  const response = await axios.post(`${apiKey1}/addSkill`, data);
  return response.data.responseData;
};

export const createPartner = async (data) => {
  const response = await axios.post(`${baseUrl}/createPartner`, data);
  return response.data.responseData;
};

export const removeLead = async (id) => {
  const response = await axios.put(`${baseUrl}/partner-lead/remove?partnerLeadId=${id}`);
  return response.data.responseData;
};

export const updateLead = async (data) => {
  const response = await axios.put(`${baseUrl}/partner-lead/update`, data);
  return response.data.responseData;
};

export const createList = async (data) => {
  console.log("Data", data);
  const response = await axios.post(`${baseUrl}/partner-lead/create`, data);
  return response.data.responseData;
};

export const CreateVendorData = async (data) => {
  const response = await axios.post(`${baseUrl}/create-vender`, data);
  return response.data.responseData;
};

export const PartnerListData = async (limit, page, filter) => {
  let finalFilter;

  if (
    !filter?.statusId &&
    !filter?.ratingId &&
    !filter?.specializationId &&
    !filter?.partnerId &&
    !filter?.cityId
  ) {
    finalFilter = { ...filter, statusId: 1 };
  } else {
    finalFilter = filter;
  }

  const response = await axios.post(
    `${baseUrl}/getCreatePartnerList?limit=${limit}&page=${page}`,
    finalFilter
  );
  return response.data.responseData;
};

export const ProjectLeadsData = async (limit, page, filter) => {
  const filteredEntries = Object.entries(filter).filter(([key, value]) => value !== "");
  const filterString = filteredEntries.map(([key, value]) => `${key}-${value}`).join(',');
  const finalFilterString = filterString ? `filter=${filterString}` : '';
  const response = await axios.get(
    `${baseUrl}/partner-leads?${finalFilterString}&limit=${limit}&page=${page}`
  );
  return response.data.responseData;
};

export const getPartnerData = async (limit, page, e) => {
  const response = await axios.get(
    `${baseUrl}/get-partner-lead-report-details?leadIds=${e}&limit=${limit}&page=${page}`
  );
  return response.data.responseData;
};

export const VendorListData = async (limit, page, filter) => {
  if (filter?.tdsApplicable) {
    const response = await axios.get(
      `${baseUrl}/get-vender-list?limit=${limit}&page=${page}&panId=${filter?.panId}&venderId=${filter?.venderId}&gstInId=${filter?.gstInId}&tdsApplicable=${filter?.tdsApplicable}`
    );
    return response.data.responseData;
  } else {
    const response = await axios.get(
      `${baseUrl}/get-vender-list?limit=${limit}&page=${page}&panId=${filter?.panId}&gstInId=${filter?.gstInId}&venderId=${filter?.venderId}`
    );
    return response.data.responseData;
  }
};

export const getPartnerList = async (limit, page, filter) => {
  if (filter?.tdsApplicable) {
    const response = await axios.get(
      `${baseUrl}/partner-leads?limit=${limit}&page=${page}&panId=${filter?.panId}&venderId=${filter?.venderId}&gstInId=${filter?.gstInId}&tdsApplicable=${filter?.tdsApplicable}`
    );
    return response.data.responseData;
  } else {
    const response = await axios.get(
      `${baseUrl}/partner-leads?limit=${limit}&page=${page}&panId=${filter?.panId}&gstInId=${filter?.gstInId}&venderId=${filter?.venderId}`
    );
    return response.data.responseData;
  }
};

export const getViewPartner = async (id) => {
  const response = await axios.get(`${baseUrl}/getPartnerById?partnerId=${id}`);
  return response.data.responseData;
};

export const getViewProject = async (id) => {
  const response = await axios.get(`${baseUrl}/partner-lead?partnerLeadId=${id}`);
  return response.data.responseData;
};

export const getViewVendor = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-vender-by-id?venderId=${id}`
  );
  return response.data.responseData;
};

export const getOnBoardingList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getOnBoardingList?partnerId=${id}`
  );
  return response.data.responseData;
};

export const getPartnerName = async () => {
  const response = await axios.get(`${baseUrl}/getPartnerListForFilter`);
  return response.data.responseData;
};

export const getSKillName = async () => {
  const response = await axios.get(`${baseUrl}/getAllSpecializatiponForfilter`);
  return response.data.responseData;
};

export const PartnerOnBoarding = async (data) => {
  const response = await axios.post(`${baseUrl}/PartnerOnBoarding`, data);
  return response.data.responseData;
};

export const getOnBoardingStatusList = async () => {
  const response = await axios.get(
    `${baseUrl}/getOnBoardingStatusList
    `
  );
  return response.data.responseData;
};

export const specialization = async (data) => {
  const response = await axios.post(`${baseUrl}/add-specialization`, data);
  return response.data.responseData;
};

export const getOnBoardingByOnBoardingId = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getOnBoardingByOnBoardingId?onboardingId=${id}`
  );
  return response.data.responseData;
};

export const getPartnerOnBoardingHistoryList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getPartnerOnBoardingHistoryList?partnerId=${id}`
  );
  return response.data.responseData;
};

export const getTdsList = async () => {
  const response = await axios.get(
    `${baseUrl}/getTdsList
    `
  );
  return response.data.responseData;
};

export const AddTds = async (data) => {
  const response = await axios.post(`${baseUrl}/add-TdsDetails`, data);
  return response.data.responseData;
};

export const getTdsDetailsList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getTdsDetailsList?partnerId=${id}`
  );
  return response.data.responseData;
};

export const ResourceType = async () => {
  const response = await axios.get(
    `${baseUrl}/get-ResourceType
    `
  );
  return response.data.responseData;
};
export const addPartnerAgreement = async (data) => {
  const response = await axios.post(`${baseUrl}/addPartnerAgreement`, data);
  return response.data.responseData;
};

export const addPartnerRating = async (data) => {
  const response = await axios.post(`${baseUrl}/addPartnerRating`, data);
  return response.data.responseData;
};

export const TdsViewByPartnerId = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-TdsViewByPartnerId?partnerId=${id}`
  );
  return response.data.responseData;
};

export const AgreementIDByData = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-agreements-by-partner-id?partnerId=${id}`
  );
  return response.data.responseData;
};

export const AgreementIDByHistory = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-agreement-history?agreementId=${id}`
  );
  return response.data.responseData;
};

export const ViewAgreementID = async (id) => {
  const response = await axios.get(
    `${baseUrl}/view-agreement?agreementId=${id}`
  );
  return response.data.responseData;
};

export const EditPartnerAgreement = async (data) => {
  const response = await axios.post(`${baseUrl}/edit-partner-agreement`, data);
  return response.data.responseData;
};

export const specializationByID = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-specialization?partnerId=${id}`
  );
  return response.data.responseData;
};

export const GetStatusType = async () => {
  const response = await axios.get(`${baseUrl}/getStateListforFilter`);
  return response.data.responseData;
};

export const getCityList = async () => {
  const response = await axios.get(
    `${baseUrl}/getCityList
    `
  );
  return response.data.responseData;
};

export const getStateList = async () => {
  const response = await axios.get(
    `${baseUrl}/getStateList
    `
  );
  return response.data.responseData;
};
export const getWorkModeList = async () => {
  const response = await axios.get(
    `${apiKey1}/getWorkModesList
    `
  );
  return response.data.responseData;
};

export const getPartnerTypeList = async () => {
  const response = await axios.get(
    `${baseUrl}/get-partner-type-for-filter
    `
  );
  return response.data.responseData;
};

export const addViewCity = async ({ id }) => {
  const response = await axios.post(`${baseUrl}/addCity?cityName=${id}`);
  return response.data.responseData;
};

export const getOverAllPartnerRating = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getOverAllPartnerRating?partnerId=${id}`
  );
  return response.data.responseData;
};

export const validateEmployeeAccess = async (id) => {
  const response = await axios.get(
    `${baseUrl}/validate-employee-access?empId=${id}`
  );
  return response.data.responseData;
};

export const editOnboarding = async (data) => {
  const response = await axios.put(`${baseUrl}/edit-onboarding`, data);
  return response.data.responseData;
};

export const getAllLead = async (id) => {
  const response = await axios.get(`${baseUrl}/get-all-lead`);
  return response.data.responseData;
};
export const getPartnerReport = async (checking, leadId, reportType) => {
  if(checking) {
  const response = await axios.get(
    `${baseUrl}/get-partner-report?leadId=${leadId}&reportType=${reportType}`
  );
  return response.data.responseData;}
  else{
    const response = await axios.get(
      `${baseUrl}/get-partner-report?recruiterId=${leadId}&reportType=${reportType}`
    );
    return response.data.responseData;
  }
};

export const getPartnerLeadReport = async (reportType) => {
  const response = await axios.get(
    `${baseUrl}/get-partner-lead-report?durationType=${reportType}`
  );
  return response.data.responseData;
};

export const getTdsSection = async () => {
  const response = await axios.get(
    `${baseUrl}/get-tds-section-list
    `
  );
  return response.data.responseData;
};

export const getPanNumberData = async () => {
  const response = await axios.get(
    `${baseUrl}/get-pan-list
    `
  );
  return response.data.responseData;
};

export const getVendorData = async () => {
  const response = await axios.get(
    `${baseUrl}/get-vendor-name-list
    `
  );
  return response.data.responseData;
};

export const getGSTData = async () => {
  const response = await axios.get(
    `${baseUrl}/get-gst-list
    `
  );
  return response.data.responseData;
};
export const downloadData = async (id) => {
  const response = await axios.get(`${baseUrl}/download-data?id=${id}`);
  return response.data.responseData;
};

export const getAllClients = async (limit, name, page) => {
  const response = await axios.get(
    `${apiKey1}/getAllClients?limit=${limit}&name=${name}&page=${page}`
  );
  return response.data.responseData;
};

export const getClientList = async (limit, page, status = 1, clientId) => {
  const queryParams = [];

  if (clientId) {
    queryParams.push(`clientId=${clientId}`);
  }

  if (status) {
    queryParams.push(`status=${status}`);
  }

  const queryString = queryParams.join("&");

  const response = await axios.get(
    `${baseUrl}/get-clients-list?limit=${limit}&page=${page}${queryString ? `&${queryString}` : ""
    }`
  );

  return response?.data?.responseData;
};

export const getClientListFilter = async () => {
  const response = await axios.get(`${baseUrl}/get-clients-for-filter`);
  return response?.data?.responseData;
};

export const getClientStatusList = async () => {
  const response = await axios.get(`${baseUrl}/get-client-status`);
  return response?.data?.responseData;
};
export const getProjectsForFilter = async () => {
  const response = await axios.get(`${baseUrl2}/getProjectforFilter`);
  return response?.data?.responseData;
};

export const getClientById = async (id) => {
  const response = await axios.get(`${apiKey1}/getClientById?clientId=${id}`);
  return response.data.responseData;
};

export const getCurrencyType = async () => {
  const response = await axios.get(`${apiKey}/getCurrency`, {
    headers: {
      Authorization: null,
    }
  });
  return response.data.responseData;
};

export const editClientDetails = async (data) => {
  const response = await axios.put(`${baseUrl}/edit-client-details`, data);
  return response.data.responseData;
};

export const addeditClientDetails = async (data) => {
  console.log("data....", data)
  const response = await axios.post(
    `${baseUrl}/add-and-edit-client-details`,
    data
  );
  return response.data.responseData;
};

export const getPartnerResource = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-partner-resources-list?partnerId=${id}`
  );
  return response.data.responseData;
};

export const addCurrency = async (data) => {
  const response = await axios.post(`${baseUrl}/add-currency`, data);
  return response.data.responseData;
};

export const createMilestone = async (data) => {
  const response = await axios.post(`${baseUrl}/create-milestone`, data);
  return response.data.responseData;
};

export const getIdClientById = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-client-by-id?clientId=${id}`
  );
  return response.data.responseData;
};

export const addProjectMilestone = async (data) => {
  const response = await axios.post(`${baseUrl}/create-milestone`, data);
  return response.data.responseData;
};

export const getMilestone = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-Milestons-by-client-id?clientId=${id}`
  );
  return response.data.responseData;
};

export const getMilestoneById = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-Milestons-by-id?milestonrId=${id}`
  );
  return response.data.responseData;
};

export const deleteVendorById = async (id) => {
  const response = await axios.delete(
    `${baseUrl}/deleteVanderOrPartner?partnerId=${id}`
  );
  return response.data.responseData;
};

export const createNote = async (data) => {
  const response = await axios.post(`${baseUrl}/partnerComment`, data);
  return response.data.responseData;
};

export const getNoteList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getPartnerCommentListByPartnerId?partnerId=${id}`
  );
  return response.data.responseData;
};

export const deleteNote = async (id) => {
  const response = await axios.delete(
    `${baseUrl}/deletePartnerComment?commentId=${id?.commentId}&commetedById=${id?.commentById}`
  );
  return response.data.responseData;
};

export const getActivePartnerDeployment = async (page, limit, data) => {
  const response = await axios.post(
    `${baseUrl2}/getAddResourceOnsite?limit=${limit}&page=${page}`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: null,
      },
    }
  );
  return response?.data?.responseData;
};
export const getAddResourceOffshore = async (page, limit, data) => {
  let data1 = {
    action: [1],
    bandwidths: [],
    empId: data.employeeId,
    projectId: [],
    skillId: [],
    salaryRange: [],
    partners: [2],
    employeeStatus: [null, null],
    handleFilter: true,
    partnerId: data.partnerId,
  };
  const response = await axios.post(
    `${baseUrl2}/getAddResourceOffshore?limit=${limit}&page=${page}`,
    data1,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: null,
      },
    }
  );
  return response?.data?.responseData;
};

export const getClientOnSite = async () => {
  const response = await axios.get(`${baseUrl2}/getClientOnSite`);
  return response?.data?.responseData;
};

export const getClientOnSitePartnerActiveDeployment = async () => {
  const response = await axios.get(`${baseUrl2}/get-all-onsite-clients-for-filter`);
  return response?.data?.responseData;
};

export const getEmployeeName = async () => {
  const response = await axios.get(`${baseUrl2}/getAllOnSiteEmployee`);
  return response?.data?.responseData?.employees;
};

export const getEmployeeNamePartnerActiveDeployment = async (isOffshore) => {
  const response = await axios.get(`${baseUrl2}/get-all-offshore-onsite-employees-for-filter?isOffshore=${isOffshore}`);
  return response?.data?.responseData;
};

export const getPartnersName = async (isOffshore) => {
  const response = await axios.get(
    `${baseUrl2}/get-all-offshore-onsite-partners-for-filter?isOffshore=${isOffshore}`
  );
  return response?.data?.responseData;
};

export const changeClientStatus = async ({ clientId, statusId }) => {
  const response = await axios.put(
    `${baseUrl}/change-client-status?clientId=${clientId}&statusId=${statusId}`
  );
  return response?.data;
};

export const changeClientProspect = async ({ clientId, statusId }) => {
  const response = await axios.put(
    `${baseUrl}/change-prospect-status?clientId=${clientId}&prospectStatus=${statusId}`
  );
  return response?.data;
};

export const deleteClient = async ({ clientId }) => {
  const response = await axios.delete(
    `${baseUrl}/delete-client?clientId=${clientId}`
  );
  return response?.data;
};

export const getLeadEmployeeList = async (employeeId) => {
  const response = await axios.get(
    `${apiKey1}/getLeadEmployeeList?leadId=${employeeId}`
  );
  return response?.data?.responseData;
};

export const changeRecruiterAllocation = async ({ partnerId, recruiterId }) => {
  const response = await axios.post(
    `${baseUrl}/changeRecruiterAllocation?partnerId=${partnerId}&recruiterId=${recruiterId}`
  );
  return response?.data?.responseData;
};

export const projectManagementList = async ({ limit, page, filterData }) => {
  const response = await axios.post(
    `${baseUrl2}/getProjects?limit=${limit}&page=${page}`,
    filterData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: null,
      },
    }
  );
  return response?.data?.responseData;
};

export const getProjectTypes = async () => {
  const response = await axios.get(`${baseUrl2}/getProjectTypes`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: null,
    },
  });

  return response?.data?.responseData;
};

export const getBillingModel = async () => {
  const response = await axios.get(`${baseUrl2}/get-billing-models`);

  return response?.data?.responseData;
};

export const getRecruiterList = async () => {
  const response = await axios.get(`${apiKey1}/get-recruiter`);
  return response?.data?.responseData;
};

export const getActiveClientData = async () => {
  const response = await axios.get(
    `${baseUrl2}/getClientFilterData?Status=Active`
  );
  return response?.data?.responseData?.clientFilterDataResponse;
};

export const getEmployeeList = async () => {
  const response = await axios.get(`${baseUrl3}/getEmployeeList`);
  return response?.data?.responseData?.employeeList;
};

export const createProject = async (data) => {
  const response = await axios.post(`${baseUrl2}/createProject`, data);
  return response;
};

export const getSkillsData = async () => {
  const response = await axios.get(`${baseUrl2}/getAllSkills`);
  return response?.data?.responseData;
};

export const getProjectDetails = async (id) => {
  const response = await axios.get(`${baseUrl2}/getProjectDetails?id=${id}`);
  return response?.data?.responseData;
};

export const editProjectDetails = async (data) => {
  const response = await axios.put(`${baseUrl2}/editProject`, data);
  return response?.data?.responseData;
};

export const addApplication = async (data) => {
  const response = await axios.post(`${baseUrl2}/addApplications`, data);
  return response?.data?.responseData;
};

export const getAllApplications = async (id) => {
  const response = await axios.get(
    `${baseUrl2}/getAllApplications?projectId=${id}`
  );
  return response?.data?.responseData;
};

export const changeProjectStatus = async (data) => {
  const response = await axios.post(`${baseUrl2}/changeProjectStatus`, data);
  return response?.data;
};
export const mailSendToPartners = async (data) => {
  const response = await axios.post(`${baseUrl}/mailSendToPartners`, data);
  return response.data?.responseData;
};
export const getExistsByClientId = async (id) => {
  const response = await axios.get(
    `${baseUrl}/exists-by-client-id?projectId=${id}`
  );
  return response?.data?.responseData;
};
export const getAllLeadsByEmployee = async ()=>{
  const response = await axios.get(`${baseUrl}/all-leads?empId=${employeeId}`);
  return response?.data?.responseData;
};
export const uploadFile = async (data)=>{
  let formData = new FormData();
  formData.append("file", data);
  const response = await axios.post(`${baseUrl}/upload-file`,formData);
  return response?.data?.responseData;
}
export const deleteFile = async (data)=>{
  const response = await axios.put(`${baseUrl}/delete-file-url?url=${data}`);
  return response?.data?.responseData;
}