import React, { useState, useEffect } from "react";
import style from "./CreateTaskComponent.module.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory, useLocation } from "react-router-dom";
import Moment from "moment";
import Select from "react-select";
import constants, { employeeId } from "../../../../Utils/constants";
import { customStylesForTaskTracker, customStylesForTaskTracker1, customStylesForTaskTracker5, customStylesForTaskTrackerSpaces } from "../../../Projects_and_Resource_Master/Clients/component/style";
import { taskTrackerAccessCode } from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import cancel from "../../../../assets/cancell.png";

const CreateTaskComponent = () => {
  function getDaysInCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    return new Date(year, month + 1, 0).getDate();
  }

  // Get the number of days in the current month
  const daysInCurrentMonth = getDaysInCurrentMonth();

  // Create an array with the days of the current month
  const daysOfMonth = Array.from({ length: daysInCurrentMonth }, (_, i) => i + 1);

  const [selectedDay, setSelectedDay] = useState(null);
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // const daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
  const location = useLocation();
  const groupIdFromUrl = location.state?.groupId;
  const { checkValidateAccess } = useValidationAccess();
  const currentDate = Moment().toDate();
  const [startDate1, setStartDate] = useState();
  const [functionalArea, setFunctionalArea] = useState([]);
  const [peojectList, setProjectList] = useState([]);
  const [disable, setDisable] = useState(true);
  const [employeeName, setEmployeeName] = useState("");
  const [relatedTo, setRelataedTo] = useState("");
  const [relatedToId, setRelataedToId] = useState(null);
  const [showRes, setshowRes] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [groupList, setGroupsList] = useState([]);
  const [arrayObj, setarrayObj] = useState([]);
  const [employeeIds, setEmployeeIds] = useState(0);
  const [option, setOption] = useState("");
  const [projectoptin, setProjectOption] = useState(null);
  const [funtionalAreaOpt, setFunctionalOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [groupId, setGroupid] = useState(location.state?.groupId);
  const [state, setState] = useState({
    description: "",
    title: "",
    frequency: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();

  const FuncTionalAreaClear = () => {
    setFunctionalOption(null);
    setFunctionalArea([]);
    getFunctionalArea();
  };

  const dayMapping = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday'
  };

  const getemployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getGroups = groupList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  function handleChange(id) {
    setEmployeeIds(id);
  }

  function handleChange1(id) {
    setRelataedToId(id);
  }

  function handleOption1(e) {
    e.preventDefault();
    setProjectOption(e.target.value);
  }

  function handleOption2(e) {
    setDisable(false);
    // e.preventDefault();
    // if (e.target.value == 1) {
    //   setDisable(false);
    //   getProject();
    // } else {
    //   setDisable(true);
    //   setProjectList([]);
    //   setProjectOption(null);
    // }
    // setFunctionalOption(e.target.value);
  }

  const hideAlert = (data) => {
    setTimeout(() => {
      setshowRes(false);
      handleRedirect(data);
    }, 1000);
  };

  const handleRedirect = () => {
    groupIdFromUrl
      ? history.push("/Groups")
      : history.push("/taskTracker/recurringtask");
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;

  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setFunctionalArea(res.data.responseData);
      })
      .catch(() => { });
  };

  const getProject = () => {
    axios
      .get(baseUrl + "/getAllActiveProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch(() => { });
  };

  const formatDate = (date) => {
    return date ? Moment(date).format("DD-MM-YYYY") : null;
  };

  function postData(e) {
    if (!state?.title || !state?.frequency || !selectedDate || !employeeIds) {
      setValidate(true);
      return;
    }
    e.preventDefault();
    const data = {
      assignedTo: employeeIds,
      planedEndDate: Moment(startDate1).format("YYYY-MM-DD hh:mm"),
      priority: "High",
      title: state.title.trim(),
      functionalFieldId: funtionalAreaOpt,
      project: projectoptin,
      taskRelatedToId: relatedToId,
      groupId: groupId,
      frequency: state.frequency,
      frequencyValue: state.frequency === "Daily" ?
        formatDate(startDate1) :
        state.frequency === "Weekly" ?
          selectedDate :
          state.frequency === "Fortnightly" ?
            " 1&16 " :
            state.frequency === "Monthly" || state.frequency === "Quarterly" ?
              selectedDate :
              null,
    };

    if (state.description) {
      data.description = state.description.trim();
    }
    setIsLoading(true);
    axios
      .post(url2 + "/createRecurringTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 1) {
          return;
        }
        if (res.data.responseStatus) {
        } else {
          setshowRes(true);
          setShowErr(false);
          hideAlert(res.data.responseData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
  }


  useEffect(() => {
    async function employeedata() {
      await axios
        .get(url + "/getEmployeeList", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          setEmployeeList(res.data.responseData.employeeList);
          setarrayObj(
            employeeList.map((item) => {
              return {
                label: item.name,
                id: item.id,
              };
            })
          );
        })
        .catch((err) => {
          return err;
        });
    }
    employeedata();
  }, []);

  useEffect(() => {
    async function getGroupData() {
      await axios
        .get(url2 + `/groupListing?empId=${employeeIds}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          if(Array.isArray(res.data.responseData)) setGroupsList(res.data.responseData);
        })
        .catch((err) => {
          return err;
        });
    }
    getGroupData();
  }, [employeeIds]);

  function onChange(e) {
    if (e.target.value === "Fortnightly") {
      setSelectedDate("1&16")
    } else if (e.target.value === "Weekly") {
      setSelectedDay(null)
      setSelectedDate(null)
    } else if (e.target.value === "Daily") {
      setSelectedDate("Daily")
    } else {
      setSelectedDate(null)
    }
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  function onChange1(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  useEffect(() => {
    getFunctionalArea();
    getProject();
  }, []);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    const fullDayName = dayMapping[day];
    setSelectedDate(fullDayName);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const CustomInputCross = ({groupId, setGroupid}) => {
    if (!groupId) return null; 
    return (
      <span style={{position: "absolute", height: "38px", width: "100%", zIndex: '1', display: "flex", alignItems: "center", paddingBottom: "2px" }}>
        <img
          src={cancel}
          alt="dropdownIcon"
          style={{ position: "absolute", zIndex: "1", marginLeft: "85%", cursor: "pointer"}}
          height="15"
          onClick={() => {setGroupid(null)}}
        />
      </span>
    );
  };

  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span
          style={{ color: 'green', cursor: 'pointer', fontSize: '16px' }}
          onClick={() => history.push('/taskTracker/recurringtask')}
        >Recurring Tasks</span><span> &gt; </span><span style={{ fontSize: '16px' }}>Create Task</span>
        {showRes && (
          <div className={style.alert} id="alert_msg">
            Task added successfully!
          </div>
        )}
        <div className={style.createTask}>
          <div id="create_task_id" className={style.gridContainer}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '95.5%' }}>
                <label className={style.font15}>
                  Task Title<span style={{ color: "red" }}>*</span><br></br>
                  <input
                    className={style.input}
                    autoComplete="off"
                    type="input"
                    id="task_title"
                    name="title"
                    value={state.title}
                    style={{ marginTop: "10px" }}
                    onChange={onChange1}
                  />
                  <p
                    className={
                      validate && !state?.title
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Task Title
                  </p>
                </label>
              </div>

            </div>
            <div>
              <div>
                <label className={style.descLabel}>
                  Task Description
                  <textarea
                    className={style.text}
                    style={{ color: "black" }}
                    name="description"
                    value={state.description}
                    onChange={onChange1}
                    id="task_description"
                  />
                </label>
              </div>
            </div>

            <div>
              <div style={{ width: "100%" }}>
                {/* <label className={style.font15} style={{ marginTop: "0px" }}>
                    Project
                    <br />
                    <select
                      onChange={(e) => handleOption1(e)}
                      disabled={disable}
                    >
                      <option value="" disabled hidden selected>
                        ---select---
                      </option>
                      {peojectList?.map((val) => {
                        return <option value={val.name}>{val?.name}</option>;
                      })}
                    </select>
                  </label> */}
              </div>
            </div>
          </div>
          <div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <label style={{ marginTop: '1px' }} className={style.font15}>
                  Assigned To<span style={{ color: "red" }}>*</span>
                  <Select
                    styles={customStylesForTaskTracker5}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setEmployeeName(e.label);
                      handleChange(e.value);
                    }}
                    options={getemployee}
                  />
                  <p
                    className={
                      validate && !employeeIds
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Assigned To
                  </p>
                </label>
              </div>
              <div style={{ width: '50%' }}>
                <label className={style.font13}
                // style={{ marginTop: "20px" }}
                >
                  Space
                  <div style={{position: "relative"}}>
                    {groupId && <CustomInputCross groupId={groupId} setGroupid={setGroupid} />}
                    <Select
                      styles={customStylesForTaskTrackerSpaces}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={groupId ? getGroups?.find(
                        (i) => i.value === groupId
                      ) : null }
                      isDisabled={!!groupIdFromUrl}
                      onChange={(e) => {
                        handleOption2(e)
                        setGroupid(e.value);
                      }}
                      options={getGroups}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className={style.frequencyContainer}>
            <label className={style.font15}>Frequency <span style={{color: "red"}}>*</span></label>
            <div className={style.radioGroup}>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Daily"
                  onChange={onChange}
                />
                Daily
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Weekly"
                  onChange={onChange}
                />
                Weekly
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Fortnightly"
                  onChange={onChange}
                />
                Fortnightly
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Monthly"
                  onChange={onChange}
                />
                Monthly
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Quarterly"
                  onChange={onChange}
                />
                Quarterly
              </label>
            </div>
            {validate && !state?.frequency && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select frequency</span>
            )}
            {state.frequency === "Weekly" && (
              <div className={style.daysOfWeekContainer}>
                {daysOfWeek.map((day) => (
                  <span
                    key={day}
                    style={{ fontSize: "13px" }}
                    className={`${style.weekday} ${selectedDay === day ? style.selectedDay : ""
                      }`}
                    onClick={() => handleDayClick(day)}
                  >
                    {day}
                  </span>
                ))}
              </div>
            )}
            {validate && state.frequency === "Weekly" && !selectedDay && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select Day</span>
            )}
            {state.frequency === "Monthly" && (
              <div className={style.daysOfMonthContainer}>
                {daysOfMonth.map((date) => (
                  <span
                    key={date}
                    className={`${style.day} ${selectedDate === date ? style.selectedDay : ""}`}
                    onClick={() => handleDateClick(date)}
                  >
                    {date}
                  </span>
                ))}
              </div>
            )}
            {validate && state.frequency === "Monthly" && !selectedDate && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select Date</span>
            )}
            {state.frequency === "Quarterly" && (
              <div className={style.daysOfMonthContainer}>
                {daysOfMonth.map((date) => (
                  <span
                    key={date}
                    className={`${style.day} ${selectedDate === date ? style.selectedDay : ""}`}
                    onClick={() => handleDateClick(date)}
                  >
                    {date}
                  </span>
                ))}
              </div>
            )}
            {validate && state.frequency === "Quarterly" && !selectedDate && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select Date</span>
            )}
          </div>
        </div>
        {checkValidateAccess(taskTrackerAccessCode?.submit_CreateTask) && (<div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
          <button className={style.cancelButton} onClick={()=>history.goBack()}>Cancel</button>
          
            <button
              className={style.submitButton}
              onClick={postData}
              disabled={isLoading}
            >
              Submit
            </button>
          
        </div>)}
      </div>
    </div>
  );
};

export default CreateTaskComponent;
